<template>
	<!--서비스 상세 페이지 - 백신-->
	<div class="Cycontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">
						거래의 기밀성을 확보해주는
					</h2>
					<h1 v-scrollanimation>CryptaLedger Tech</h1>
					<p>
						CryptaLedger Tech은 동형암호화 기술을 활용하여 거래 데이터를
						암호화된 <br />상태에서 분산원장에 저장하고 유통할 수 있도록 하는
						혁신적인 솔루션입니다. <br />분산원장을 통해 계정 정보와 트랜잭션
						정보를 보호하며, <br />
						거래 내용이 암호화된 상태로도 유효성 검사를 거쳐 기밀성과 안전성을
						유지합니다. <br />
						복호화 과정 없이도 사용자 데이터를 보호하는 최첨단 보안 기술입니다.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<router-link to="/solutionvaccine">
							<button class="btn--md btn--fill-gray">솔루션 바로가기</button>
						</router-link>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<img :src="cryptaledgermain" />
				</div>
			</div>
		</div>
		<!--content1 end-->
		<!--contnet2-->
		<div id="content2">
			<div class="servicecontent__wrapper--imgbg">
				<img :src="cryptaledgerbg" />
				<div class="servicecontent__textbox">
					<h1 v-scrollanimation>거래의 안전성을 강화해주는</h1>
					<div class="servicecontent_stroke"></div>
					<h2 v-scrollanimation>CryptaLedger Tech</h2>
					<p v-scrollanimation>
						고급 암호화 기술을 기반으로 거래의 무결성과 기밀성을 보장하며,<br />
						데이터 보호를 극대화합니다.
					</p>
				</div>
			</div>
			<div class="servicecontent2_box">
				<div class="servicecontent2_textbox">
					<h1>
						기밀성과 무결성을 보장하는 분산원장 보안 기술<br />
						<span>CryptaLedger Tech</span>
					</h1>
					<div class="border-graient"></div>
					<p>
						CryptaLedger Tech은 사용자가 안전한 환경에서 거래 데이터를
						<br />분산원장에 저장하고 조회할 수 있도록 하여, <br />거래의
						기밀성과 무결성을 보장합니다. <br />또한, Public Blockchain에
						적용시에 동형암호를 통하여 <br />데이터 유출을 원천봉쇄하고 안전한
						보안환경을 제공합니다.
					</p>
				</div>
			</div>
		</div>
		<!--content3-->
		<div id="content3">
			<div class="content3-title_bg"></div>
			<div class="content3-title">
				<h1>거래의 기밀성과 안전성을 한층 강화하다</h1>
				<p class="servicevaccine-content3">
					거래의 기밀성과 안전성을 보장하는 CryptaLedger Tech를 통해, <br />
					금융 및 의료기관의 민감한 정보를 암호화된 상태에서 <br />안전하게
					관리하고, 다양한 환경에서 철저하게 보호하세요!
				</p>
			</div>
			<div class="content3_box">
				<img :src="content3img" />
			</div>
		</div>
		<!--content3 end-->
		<div id="content4">
			<div class="content4-title">
				<p>CryptaLedger Tech Detail Service</p>
				<h2>CryptaLedger Tech 서비스</h2>
			</div>

			<div class="content4_box">
				<div class="content_box">
					<div class="img_box1"><img :src="echc" /></div>
					<div class="text_box">
						<h2>ECHC 암호 시스템</h2>
						<h3>ECHC 암호시스템을 사용한 분산원장 저장 기술</h3>
						<div class="border"></div>
						<p>
							ECHC 암호시스템은 데이터를 암호화하여 분산원장에 <br />안전하게
							저장하는 기술입니다. 타원곡선 기반 동형암호를 <br />
							사용하여 데이터의 기밀성과 무결성을 유지하며, <br />퍼블릭
							블록체인에서도 강력한 보안을 제공합니다.
						</p>
					</div>
				</div>

				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>zk-FIDNA 시스템</h2>
						<h3>FIDNA 영지식 증명 기술을 사용한 유효성 검사</h3>
						<div class="border"></div>
						<p>
							zk-FINDA 시스템은 zk-SNARKs 기반의 영지식 증명 기술과<br />
							타원곡선 기반 동형암호(ECHC)를 결합하여, 데이터를 암호화된<br />
							상태에서 안전하게 처리하고 검증할 수 있습니다. 이 시스템은<br />퍼블릭
							블록체인 환경에서 데이터의 기밀성과 무결성을 보장하며,<br />신뢰할
							수 있는 보안 환경을 제공합니다.
						</p>
					</div>
					<div class="img_box2"><img :src="fidna" /></div>
				</div>

				<div class="content_box">
					<div class="img_box1">
						<img :src="fidna2" />
					</div>

					<div class="text_box">
						<h2>zk-FIDTA 시스템</h2>
						<h3>FIDTA 영지식 증명 기술을 사용한 유효성 검사</h3>
						<div class="border"></div>
						<p>
							FIDTA 시스템은 FIDTA 영지식 증명 기술을 활용하여 데이터의
							<br />무결성과 진위 여부를 안전하게 검증합니다. 이 시스템은
							거래<br />
							과정에서 데이터가 노출되지 않도록 보호하면서도, <br />신뢰할 수
							있는 유효성 검사를 수행합니다.<br />이를 통해 블록체인 거래의
							보안을 강화하고, 효율성을 제공합니다.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="cryptaledger_content">
			<h1>Privy Encryption</h1>
			<h2>암호화된 데이터의 분산원장 저장 기술</h2>
			<div class="border-cryptaledger"></div>
			<p>
				Privy Encryption은 CrptaLedger Tech의 첨단 암호화 기술들을 결합하여
				<br />데이터를 안전하게 분산원장에 저장하는 혁신적인 시스템입니다.<br />이
				기술은 타원곡선 기반 동형암호(ECHC)와 영지식 증명 기술(zk-FIDNA,
				zk-FIDTA)을 통합하여,<br />퍼블릭 블록체인(예: 비트코인, 이더리움)에서
				거래의 기밀성과 무결성을 철저히 보장합니다.<br />이러한 기술의 통합은
				한국은행의 CBDC와 다양한 금융 기관, 보험사, 투자사의 거래 기밀성을
				유지하며,<br />최상의 보안을 제공하여 신뢰성을 크게 향상시킵니다.<br /><br />Privy
				Encryption은 금융 및 다양한 산업에서 강력한 보호 수단으로 자리 잡고
				있습니다.
			</p>
		</div>

		<!--content4 end-->

		<!--content5-->
		<div id="content5Cy">
			<!--content5 title-->
			<div class="content5cy-title">
				<p>Utilization by industry</p>
				<h2>산업별 활용 사례</h2>
			</div>
			<!--content5_box-->
			<div class="content5_box">
				<!--box1-->
				<div class="box1">
					<div class="box_title">
						<h1>01</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>금융 기관</h2>
						<p>
							거래 데이터의 암호화와 분산원장 저장을 통해<br />투명성과 보안
							강화 및 금융 사기 방지
						</p>
					</div>
				</div>
				<!--box1 end-->
				<!--box2-->
				<div class="box2">
					<div class="box_title">
						<h1>02</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>의료기관</h2>
						<p>
							환자 정보를 암호화하여 안전하게 저장함으로써<br />개인정보 유출
							방지 및 데이터 무결성 보장
						</p>
					</div>
				</div>
				<!--box2 end-->
				<!--box3-->
				<div class="box3">
					<div class="box_title">
						<h1>03</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>공공기관</h2>
						<p>
							정부 데이터를 암호화하여 <br />분산저장 함으로써 정보 보호 강화<br />및
							전자정부 서비스 신뢰성 향상
						</p>
					</div>
				</div>
			</div>
			<!--box3 end-->
			<!--content5_box end-->
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="content6">
			<SwiperKr />
		</div>
		<!--content6 end-->
		<!--content7-->
		<!-- <div class="servicecontent__wrapper center bg contents7">
			<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
			<p>
				필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
				지금 바로 진단페이지를 통해 제품을 받아보세요!
			</p>
			<div>
				<button class="btn--md btn--fill-accent">
					<router-link to="/warning">진단 페이지</router-link></button
				><button class="btn--md btn--fill-gray">
					<router-link to="/inquiry">문의 하기</router-link>
				</button>
			</div>
		</div> -->

		<div class="mb__Cont4">
			<div class="TopText_mb">요금이 궁금하신가요?</div>
			<div class="BtmText_mb">내 비지니스 유형에 적합한 플랜을 찾아보세요!</div>
			<router-link to="/pricingsecurity" class="mb-price-button">
				모듈러스 요금 확인
			</router-link>
			<router-link to="/pricingbimining" class="mb-price-button">
				BI Mining 요금 확인
			</router-link>
			<div class="TopText_mb">솔루션이 궁금하신가요?</div>
			<div class="BtmText_mb">
				내 비지니스 유형에 적합한 솔루션을 찾아보세요!
			</div>
			<router-link to="/solutioncrypto" class="mb-solution-button">
				암호스키마 솔루션 바로가기
			</router-link>
			<router-link to="/solutionvaccine" class="mb-solution-button">
				FC-Anti Virus 솔루션 바로가기
			</router-link>
			<router-link to="/" class="mb-solution-button">
				CryptaLedger Tech<br />
				솔루션 바로가기
			</router-link>
		</div>
	</div>
	<div class="Cycontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">
						Ensuring Confidentiality of Transactions
					</h2>
					<h1 v-scrollanimation>CryptaLedger Tech</h1>
					<p>
						CryptaLedger Tech is an innovative solution that utilizes
						homomorphic <br />encryption technology to securely store and
						distribute transaction data <br />in an encrypted form within a
						distributed ledger. <br />
						By leveraging the distributed ledger, it protects account
						information <br />
						and transaction details while maintaining confidentiality and
						security <br />through encrypted data validation. This cutting-edge
						security<br />technology protects user data without the need for
						decryption.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<router-link to="/solutionvaccine">
							<button class="btn--md btn--fill-gray">Go to Solution</button>
						</router-link>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<img :src="cryptaledgermain" />
				</div>
			</div>
		</div>
		<!--content1 end-->
		<!--contnet2-->
		<div id="content2">
			<div class="servicecontent__wrapper--imgbg">
				<img :src="cryptaledgerbg" />
				<div class="servicecontent__textbox">
					<h1 v-scrollanimation>Enhancing Transaction Security</h1>
					<div class="servicecontent_stroke"></div>
					<h2 v-scrollanimation>CryptaLedger Tech</h2>
					<p v-scrollanimation>
						Based on advanced encryption technology, <br />
						it ensures the integrity and confidentiality of transactions
						<br />while maximizing data protection.
					</p>
				</div>
			</div>
			<div class="servicecontent2_box">
				<div class="servicecontent2_textbox tech">
					<h1>
						Distributed Ledger Security Technology Ensuring Confidentiality and
						Integrity<br />
						<span>CryptaLedger Tech</span>
					</h1>
					<div class="border-graient"></div>
					<p>
						CryptaLedger Tech allows users to securely store and retrieve
						<br />transaction data in a distributed ledger, ensuring the
						<br />confidentiality and integrity of transactions.
						<br />Additionally, when applied to Public Blockchain, <br />it
						prevents data leakage through homomorphic encryption,
						<br />providing a secure and safe environment.
					</p>
				</div>
			</div>
		</div>
		<!--content3-->
		<div id="content3">
			<div class="content3-title_bg"></div>
			<div class="content3-title">
				<h1>
					Further enhance the confidentiality and<br />security of transactions
				</h1>
				<p class="servicevaccine-content3">
					With CryptaLedger Tech, which guarantees confidentiality <br />
					and security of transactions, manage sensitive information<br />of
					financial and medical institutions safely in an encrypted<br />state
					and thoroughly protect it in various environments!
				</p>
			</div>
			<div class="content3_box">
				<img :src="content3imgen" />
			</div>
		</div>
		<!--content3 end-->
		<div id="content4">
			<div class="content4-title">
				<h2>CryptaLedger Tech Detail Service</h2>
			</div>

			<div class="content4_box">
				<div class="content_box">
					<div class="img_box1"><img :src="echc" /></div>
					<div class="text_box">
						<h2>ECHC Cryptosystem</h2>
						<h3>
							Distributed Ledger Storage Technology Using<br />
							ECHC Cryptosystem
						</h3>
						<div class="border"></div>
						<p>
							The ECHC Cryptosystem is a technology that securely<br />stores
							encrypted data in a distributed ledger. By utilizing<br />
							the elliptic curve homomorphic cryptosystem, it ensures<br />the
							confidentiality and integrity of data, while also<br />providing
							robust security even in public blockchains.
						</p>
					</div>
				</div>

				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>zk-FIDNA System</h2>
						<h3>Validation Using FIDNA Zero-Knowledge<br />Proof Technology</h3>
						<div class="border"></div>
						<p>
							The zk-FINDA system combines zk-SNARKs-based <br />
							zero-knowledge proof technology with the elliptic curve<br />
							homomorphic cryptosystem, enabling secure processing and
							<br />verification of encrypted data. This system ensures the
							<br />confidentiality and integrity of data in a public
							blockchain<br />environment, providing a trusted security
							framework.
						</p>
					</div>
					<div class="img_box2"><img :src="fidna" /></div>
				</div>

				<div class="content_box">
					<div class="img_box1">
						<img :src="fidna2" />
					</div>

					<div class="text_box">
						<h2>zk-FIDNA System</h2>
						<h3>
							Validity Verification Using FIDTA Zero-Knowledge<br />Proof
							Technology
						</h3>
						<div class="border"></div>
						<p>
							The zk-FIDTA system utilizes FIDTA zero-knowledge
							<br />proof technology to securely verify the integrity and <br />
							authenticity of data. This system protects data from
							<br />exposure during transactions while performing
							<br />trustworthy validity checks. By doing so, it enhances the<br />security
							of blockchain transactions and provides greater efficiency.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="cryptaledger_content">
			<h1>Privy Encryption</h1>
			<h2>Distributed Ledger Storage Technology for Encrypted Data</h2>
			<div class="border-cryptaledger"></div>
			<p>
				Privy Encryption is an innovative system that securely stores data in a
				<br />distributed ledger by combining advanced encryption technologies
				from<br />CryptaLedger Tech. This technology integrates elliptic
				curve-based<br />homomorphic cryptosystem (ECHC) and zero-knowledge
				proof technology<br />(zk-FIDNA, zk-FIDTA) to ensure the confidentiality
				and integrity of <br />transactions even on public blockchains (e.g.,
				Bitcoin, Ethereum).<br />The integration of these technologies ensures
				the confidentiality of <br />transactions for the Bank of Korea's CBDC,
				as well as for various financial<br />institutions, insurance companies,
				and investors, offering top-tier security<br />and significantly
				enhancing trustworthiness.<br /><br />Privy Encryption has become a
				powerful protection mechanism in finance <br />and various industries.
			</p>
		</div>

		<!--content4 end-->

		<!--content5-->
		<div id="content5Cy">
			<!--content5 title-->
			<div class="content5cy-title">
				<h2>Utilization by industry</h2>
			</div>
			<!--content5_box-->
			<div class="content5_box">
				<!--box1-->
				<div class="box1">
					<div class="box_title">
						<h1>01</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Financial Institutions</h2>
						<p>
							Enhancing transparency and security, <br />and preventing
							financial fraud through encryption of transaction data<br />and
							distributed ledger storage.
						</p>
					</div>
				</div>
				<!--box1 end-->
				<!--box2-->
				<div class="box2">
					<div class="box_title">
						<h1>02</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Healthcare Institutions</h2>
						<p>
							Encrypting and securely storing patient information to
							<br />prevent personal data leakage and ensure data integrity.
						</p>
					</div>
				</div>
				<!--box2 end-->
				<!--box3-->
				<div class="box3">
					<div class="box_title">
						<h1>03</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>Public Institutions</h2>
						<p>
							By encrypting and distributing<br />government data, this
							enhances<br />information protection and <br />improves the
							reliability of <br />e-government services.
						</p>
					</div>
				</div>
			</div>
			<!--box3 end-->
			<!--content5_box end-->
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="content6">
			<SwiperKr />
		</div>
		<!--content6 end-->
		<!--content7-->
		<!-- <div class="servicecontent__wrapper center bg contents7">
			<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
			<p>
				필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
				지금 바로 진단페이지를 통해 제품을 받아보세요!
			</p>
			<div>
				<button class="btn--md btn--fill-accent">
					<router-link to="/warning">진단 페이지</router-link></button
				><button class="btn--md btn--fill-gray">
					<router-link to="/inquiry">문의 하기</router-link>
				</button>
			</div>
		</div> -->

		<div class="mb__Cont4">
			<div class="TopText_mb">Curious about the pricing?</div>
			<div class="BtmText_mb">
				Find the plan that's right for your business type!
			</div>
			<router-link to="/pricingsecurity" class="mb-price-button">
				Modulus Check Pricing
			</router-link>
			<router-link to="/pricingbimining" class="mb-price-button">
				BI Mining Check Pricing
			</router-link>
			<div class="TopText_mb">Curious about the solution?</div>
			<div class="BtmText_mb">
				Find the plan that's right for your business type!
			</div>
			<router-link to="/solutioncrypto" class="mb-solution-button">
				Crypto Schema Go to Solution
			</router-link>
			<router-link to="/solutionvaccine" class="mb-solution-button">
				FC-Anti Virus Go to Solution
			</router-link>
			<router-link to="/" class="mb-solution-button">
				CryptaLedger Tech <br />Go to Solution
			</router-link>
		</div>
	</div>
</template>

<script>
import fcanti from '../assets/images/service/fcanti.svg';
import cryptaledgerbg from '../assets/images/service/cryptaledgerbg.svg';
import cryptaledgercontentbg from '../assets/images/service/cryptaledger-contentbg.svg';
import speciality4 from '../assets/images/service/speciality4.svg';
import speciality5 from '../assets/images/service/speciality5.svg';
import speciality6 from '../assets/images/service/speciality6.svg';
import cryptaledgermain from '../assets/images/service/cryptaledgermain.svg';
import content3img from '../assets/images/service/servicecryptaledger-content3.png';
import content3imgen from '../assets/images/service/servicecryptaledger-content3en.png';
import echc from '../assets/images/service/cryptaledger-echc.svg';
import fidna from '../assets/images/service/cryptaledger-zk-fidna.png';
import fidna2 from '../assets/images/service/cryptaledger-zk-fidna2.svg';
import anti from '../assets/images/service/vaccine-fc-antivirus.svg';

export default {
	data() {
		return {
			service: 1,
			fcanti,
			cryptaledgerbg,
			cryptaledgercontentbg,
			speciality4,
			speciality5,
			speciality6,
			cryptaledgermain,
			content3img,
			content3imgen,
			echc,
			fidna,
			fidna2,
			anti,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
